
import React, { Component } from "react"
import { ExposureContext } from "../context"

/**
 * Component CreateModel: This will handle the elements to create a new
 * exposure. Containing:
 *
 *  - The input field for the name.
 *  - File fields to upload: 
 *    - Location file.
 *    - Lccount file.
 *    - R1 info 
 *    - R1 scope
 *  - Create button to create the new exposure.
 *
 * @state:
 *   Contains the file name for the state
 *
 * @context:
 *   We will call the createNewExposure method with all the information
 *   need.
 *
 */
class CreateModel extends Component {
  static contextType = ExposureContext;
  state = {
        "name": ""
      }
  onChange = e => {
    this.setState({
        name:e.target.value
    });
  };
  render() {
    return (
      <div class="card shadow mb-4">
        <div class="card-body">
          <p></p>
          <h5>Create New Model Run:</h5>
          <div class="row">
              <div class="col-xl-6 col-md-6 mb-4">
                  <input
                    class="form-control form-control-user" 
                    value={this.state.name}
                    placeholder="new model run"
                    onChange={this.onChange}
                  />
                  <p></p>
              </div>
              <div class="col-xl-3 col-md-6 mb-4">
                  <div class="btn btn-success btn-icon-split" onClick={() => this.context.createNewModel(this.state.name)}>
                      <span class="icon text-white-50">
                          <i class="fas fa-check"></i>
                      </span>
                      <span class="text">OK</span>
                  </div>
              </div>
          </div>
          <div class="btn btn-primary btn-icon-split" onClick={() => this.context.runModel(this.context.active_model)}>
              <span class="icon text-white-50">
                  <i class="fas fa-arrow-right"></i>
              </span>
              <span class="text">Execute Run</span>
          </div>    
        </div>
      </div>
    )
  }
}

export default CreateModel
