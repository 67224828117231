import React, { Component } from "react"
import TopBar from "./TopBar"
import ActiveExposure from "./ActiveExposure"
import ActiveModel from "./ActiveModel"
import DataVisualization from "./DataVisualization"
import { ExposureContext } from "../context"

/**
 * View for exposures.
 *
 * Will show the information from already created exposures.
 * Will have the capability to create new exposures.
 *
 */
class ExposureOverview extends Component {
  static contextType = ExposureContext;
  
  isReportValid() {
    return (this.context.model_outputs && this.context.model_outputs.aep);
  }

  render() {
    return (
        <div id="content-wrapper" class="d-flex flex-column">
          <div id="content">
            <TopBar />
            <div class="container-fluid">
              <div class="d-sm-flex align-items-center justify-content-between mb-4">
                <h1 class="h3 mb-0 text-gray-800">Outputs</h1>
                <button disabled={!this.isReportValid()} class="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm" onClick={() => this.context.modelGenerateReport(this.context.active_model)}>
                  <i class="fas fa-download fa-sm text-white-50"></i>Generate Report
                </button>
              </div>
              <ActiveExposure />
              <ActiveModel />
              <DataVisualization />
            </div>
          </div>
        </div>
    )
  }
}

export default ExposureOverview 
