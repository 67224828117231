import React, { Component } from "react"
import { ExposureContext } from "../context"
import Chart from "chart.js";

// Set new default font family and font color to mimic Bootstrap's default styling
// Chart.defaults.global.defaultFontFamily = 'Nunito', '-apple-system,system-ui,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif';
Chart.defaults.global.defaultFontColor = '#858796';

function number_format(number, decimals, dec_point, thousands_sep) {
  // *     example: number_format(1234.56, 2, ',', ' ');
  // *     return: '1 234,56'
  number = (number + '').replace(',', '').replace(' ', '');
  var n = !isFinite(+number) ? 0 : +number,
    prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
    sep = (typeof thousands_sep === 'undefined') ? ',' : thousands_sep,
    dec = (typeof dec_point === 'undefined') ? '.' : dec_point,
    s = '',
    toFixedFix = function(n, prec) {
      var k = Math.pow(10, prec);
      return '' + Math.round(n * k) / k;
    };
  // Fix for IE parseFloat(0.55).toFixed(0) = 0;
  s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.');
  if (s[0].length > 3) {
    s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
  }
  if ((s[1] || '').length < prec) {
    s[1] = s[1] || '';
    s[1] += new Array(prec - s[1].length + 1).join('0');
  }
  return s.join(dec);
}

// Area Chart Example
/**
 * Component ModelTable show the information for the different models
 * in a table format. 
 *
 * @Childs: ModelItem
 *
 */
class GraphVisualization extends Component {
  constructor(props){
    super(props);
    this.chartRef = React.createRef();
    this.data = undefined;
    this.model = 0;
  }

  componentDidMount() {
    this.loadGraph(); 
  }
  componentDidUpdate() {
    this.loadGraph();
  }

  loadGraph() {
    console.log("Active model: ", this.context.active_model, "model: ", this.model)
    if (this.props.data !== undefined) {
        if (this.context.active_model !== this.model) {
            this.data = this.props.data
            this.model = this.context.active_model
        }
    }

    if (this.model === 0) {
        this.data = undefined
    }

    let data = this.data
    data = this.context.model_outputs
    console.log("At graph update")
    console.log("Context", this.context.model_outputs)
    let ctx = this.chartRef.current;
    if (data === undefined || Object.keys(data).length === 0){
        console.log("Data is undefined")
        new Chart(ctx, {}).render();
        return
    }
    if (ctx === null){
        console.log("ctx is undefined")
        return
    }
    console.log("Data is", data)
    console.log(data[this.props.name]["1"]["return_period"])
    console.log(data[this.props.name]["1"]["loss"])
    console.log(data[this.props.name]["2"]["loss"])
    console.log("this.props.name ", this.props.name)
    new Chart(ctx, {
      type: 'line',
      data: {
        labels: data[this.props.name]["1"]["return_period"].reverse(),
        datasets: [{
          label: this.props.name.toLocaleUpperCase() + " Analytical",
          lineTension: 0.3,
          backgroundColor: "rgba(78, 115, 223, 0.05)",
          borderColor: "rgba(78, 115, 223, 1)",
          pointRadius: 3,
          pointBackgroundColor: "rgba(78, 115, 223, 1)",
          pointBorderColor: "rgba(78, 115, 223, 1)",
          pointHoverRadius: 4,
          pointHoverBackgroundColor: "rgba(78, 115, 223, 1)",
          pointHoverBorderColor: "rgba(78, 115, 223, 1)",
          pointHitRadius: 10,
          pointBorderWidth: 2,
          data: data[this.props.name]["1"]["loss"].reverse(),
        },
        {
          label: this.props.name.toLocaleUpperCase() + " Sample",
          lineTension: 0.3,
          backgroundColor: "rgba(78, 115, 223, 0.05)",
          borderColor: "rgba(255, 115, 223, 1)",
          pointRadius: 3,
          pointBackgroundColor: "rgba(255, 115, 223, 1)",
          pointBorderColor: "rgba(255, 115, 223, 1)",
          pointHoverRadius: 4,
          pointHoverBackgroundColor: "rgba(255, 115, 223, 1)",
          pointHoverBorderColor: "rgba(255, 115, 223, 1)",
          pointHitRadius: 10,
          pointBorderWidth: 2,
          data: data[this.props.name]["2"]["loss"].reverse(),
        }],
      },
      options: {
        maintainAspectRatio: false,
        layout: {
          padding: {
            left: 10,
            right: 25,
            top: 25,
            bottom: 0
          }
        },
        scales: {
          xAxes: [{
            time: {
              unit: 'date'
            },
            gridLines: {
              display: false,
              drawBorder: false
            },
            ticks: {
              maxTicksLimit: 7
            }
          }],
          yAxes: [{
            ticks: {
              maxTicksLimit: 5,
              padding: 10,
              // Include a dollar sign in the ticks
              callback: function(value, index, values) {
                return '$' + number_format(value);
              }
            },
            gridLines: {
              color: "rgb(234, 236, 244)",
              zeroLineColor: "rgb(234, 236, 244)",
              drawBorder: false,
              borderDash: [2],
              zeroLineBorderDash: [2]
            }
          }],
        },
        legend: {
          display: false
        },
        tooltips: {
          backgroundColor: "rgb(255,255,255)",
          bodyFontColor: "#858796",
          titleMarginBottom: 10,
          titleFontColor: '#6e707e',
          titleFontSize: 14,
          borderColor: '#dddfeb',
          borderWidth: 1,
          xPadding: 15,
          yPadding: 15,
          displayColors: false,
          intersect: false,
          mode: 'index',
          caretPadding: 10,
          callbacks: {
            label: function(tooltipItem, chart) {
              var datasetLabel = chart.datasets[tooltipItem.datasetIndex].label || '';
              return datasetLabel + ': $' + number_format(tooltipItem.yLabel);
            }
          }
        }
      }
    });
    // reset props data
    this.props.data = undefined
  }

  static contextType = ExposureContext;

  render() {
    return (
        <div class="col-xl-7 col-lg-7">
          <div class="card-body">
            <div>
              <div class="chartjs-size-monitor">
                <div class="chartjs-size-monitor-expand">
                  <div class=""></div>
                </div>
                <div class="chartjs-size-monitor-shrink">
                  <div class=""></div>
                </div>
              </div>
              <canvas ref={this.chartRef} id={this.props.name+"graph"} width="1039" height="420" class="chartjs-render-monitor"></canvas>
            </div>
          </div>
        </div>
    )
  }
}

export default GraphVisualization
