import React, { Component } from "react"
import ModelItem from "./ModelItem"
import { ExposureContext } from "../context"

/**
 * Component ModelTable show the information for the different analyses
 * in a table format. 
 *
 * @Childs: ModelItem
 *
 */
class ModelTable extends Component {
  static contextType = ExposureContext;
  render() {
    var exposures = this.context.exposures
    var models = this.context.models
    exposures = Object.keys(exposures).map(
        function (key) { return exposures[key]; });
    models = Object.keys(models).map(
        function (key) { return models[key]; });
    return (
        <div class="card shadow mb-4">
            <div class="card-body">
		<div class="row">
		    <div class="ml-3">
			<h5>Model Runs</h5>
		    </div>
		    <div class="ml-3">
			<div onClick={() => this.context.reloadModel(this.context.active_exposure)} class="btn-sm btn-info btn-circle">
			    <i class="fas fa-redo"></i>
			</div>
		    </div>
		    <div class="ml-3">
			<div onClick={() => this.context.loadGeocoded()} class="btn-sm btn-info btn-circle">
			    <i class="fas fa-globe"></i>
			</div>
		    </div>
		</div>
                <div class="table-responsive">
                    <table class="table table-bordered" id="dataTable" width="100%" cellspacing="0">
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Name</th>
                                <th>Created</th>
                                <th>Geocoded</th>
				<th>Status</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {models.map(model => (
				<ModelItem
				    id={model.id} 
				    model={model}
				/>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
  }
}

export default ModelTable
