import React, { Component } from "react"
import SideBar from "./SideBar"
import ExposureOverview from "./ExposureOverview"
import ModelRun from "./ModelRun"
import Outputs from "./Outputs"
import { ExposureContext } from "../context"

/**
 * Application wrapper.
 */
class Wrapper extends Component {
  static contextType = ExposureContext;
  render() {
    return (
        <div id="wrapper">
          <SideBar />
          {this.context.selected === "exposures" ?  <ExposureOverview />: "" }
          {this.context.selected === "models" ?  <ModelRun />: "" }
          {this.context.selected === "outputs" ?  <Outputs />: "" }
          {console.log(this.context.selected)}
          {console.log(typeof this.context.selected)}
        </div>
    )
  }
}

export default Wrapper
