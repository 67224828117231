
import React, { Component } from "react"
import { ExposureContext } from "../context"
import Moment from "moment"

/**
 * ExposureItem Component, Show each row of the exposure table.
 */
class ModelItem extends Component {
  static contextType = ExposureContext;
    render() {
	let geocoded = "N/A";
	if (this.props.model.geocoded_match) {
	    geocoded = this.props.model.geocoded_match + "%";
	}
	let model_status = this.props.model.status;
	if (this.props.model.status === "RUN_COMPLETED") {
	    model_status = "Run COMPLETED";
	}
	else if (this.props.model.status === "RUN_STARTED") {
	    model_status = "Run STARTED";
	}
	else if (this.props.model.status === "RUN_ERROR") {
	    model_status = "Run FAILED";
	}
	else if (this.props.model.status === "READY" ) {
	    model_status = "READY to run";
	}
	else if (this.props.model.status === "NEW") {
	    model_status = "READY for inputs";
	}
	else if (this.props.model.status === "INPUTS_GENERATION_QUEUED") {
	    model_status = "GENERATING inputs";
	}
	else if (this.props.model.status === "INPUTS_GENERATION_ERROR") {
	    model_status = "Inputs FAILED";
	}
    return (
      <tr>
          <td>{this.props.model.id}</td>
          <td>{this.props.model.name}</td>
          <td>{Moment(this.props.model.created).format("DD/MM/YYYY")}</td>
          <td>{geocoded}</td>
	  <td>{model_status}</td>
          <td>
              <div onClick={() => this.context.runModel(this.props.model.id)} class="btn-sm btn-primary btn-circle">
                  <i class="fas fa-play"></i>
              </div>
              <div onClick={() => this.context.deleteModel(this.props.model.id)} class="btn-sm btn-danger btn-circle ml-1">
                  <i class="fas fa-trash"></i>
              </div>
          </td>
      </tr>
    )
  }
}

export default ModelItem
