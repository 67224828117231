import React, { Component } from "react"
import ActiveExposureItem from "./ActiveExposureItem.js"
import { ExposureContext } from "../context"
import Moment from "moment"

/**
 * Component ExposureTable show the information for the different exposures
 * in a table format. 
 *
 * @Childs: ExposureItem
 *
 */
class ActiveExposure extends Component {
  static contextType = ExposureContext;

  selectItem(exposure) {
    this.context.setState({active_exposure: exposure})
  }


  render() {
    var exposures = this.context.exposures
    exposures = Object.keys(exposures).map(
         function (key) { return exposures[key]; });

    let exposure_table;
    // Condition is kind of redundant, used to prevent the case where
    // active_exposure object already exists but id has not been loaded yet.
    if (this.context.active_exposure) {
	let current_exposure = this.context.exposures[this.context.active_exposure];    
        exposure_table = (
            <table class="table table-bordered mt-2" id="dataTable" width="100%" cellSpacing="0">
                <tbody>
                    <tr>
                        <td>{current_exposure.id}</td>
                        <td>{current_exposure.name}</td>
                        <td>{Moment(current_exposure.modified).format("DD/MM/YYYY")}</td>
                        <td>{current_exposure.tiv}</td>
                    </tr>
                </tbody>
            </table>
        )
    }
    return (
        <div class="card shadow mb-4">
            <div class="card-body">
                <h3>Active Exposure</h3>
                <div class="dropdown mb-4">
                    <button class="btn btn-primary dropdown-toggle" type="button" id="dropdownExposureButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        Select Exposure
                    </button>
                    <div class="dropdown-menu animated--fade-in" aria-labelledby="dropdownExposureButton">
                        {exposures.map(exp => (
                            <ActiveExposureItem
                                id={exp.id}
                                name={exp.name}
                                change={this.context.setActiveExposure}
                            />
                        ))}
                    </div>
                    {exposure_table}
                </div>
            </div>
        </div>
    )
  }
}

export default ActiveExposure
