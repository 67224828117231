import React, { Component } from "react"
import { ExposureContext } from "../context"
import mapboxgl from 'mapbox-gl/dist/mapbox-gl-csp';
// eslint-disable-next-line import/no-webpack-loader-syntax
import MapboxWorker from 'worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker';

mapboxgl.workerClass = MapboxWorker;
mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN;

const ZOOM_PADDING = 100;
const INITIAL_LAT = 32.7890765;
const INITIAL_LNG = 130.7412906;

class ExposureMap extends Component {
    static contextType = ExposureContext;


    constructor(props) {
        super(props);

        this.state = {
            lng: INITIAL_LNG,
            lat: INITIAL_LAT,
            zoom: 5,
            locations: {}
        }
    }

    componentDidMount() {
        this.setState({
            locations: this.context.locations
        });

        this.map = new mapboxgl.Map({
            container: "exposure-map",
            style: 'mapbox://styles/mapbox/light-v10/',
            center: [this.state.lng, this.state.lat],
            zoom: this.state.zoom
        });

        this.map.on('load', () => {
            console.log("At Load:");
            console.log(this.state.locations);
            this.loadMap({});
        });

        this.map.on('move', () => {
            this.setState({
                lng: this.map.getCenter().lng.toFixed(4),
                lat: this.map.getCenter().lat.toFixed(4),
                zoom: this.map.getZoom().toFixed(2)
            });
        });
    }    
    
    componentDidUpdate(prevProps) {
        console.log("At update")
        if (this.context.locations !== this.state.locations) {
            console.log("At update, inside if", this.context.locations)
            this.exposure = this.context.active_exposure;
            this.setState({locations: this.context.locations});
            this.destroyMap()
            this.loadMap(this.context.locations)
        }
    }
    
    destroyMap() {
        this.map.removeLayer("locations_individual");
        this.map.removeLayer("locations_outer");
        this.map.removeLayer("locations_cluster_label");
        this.map.removeSource("locations");
    }
    loadMap(locations) {
        console.log("Load map:", locations)

        this.map.addSource('locations', {
            type: "geojson",
            data: locations,
            cluster: true,
            clusterRadius: 50
        });
        this.map.addLayer({
            'id': 'locations_individual',
            'type': 'circle',
            'source': 'locations',
            'filter': [
                'all',
                ['!=', ['get', 'cluster'], true]
            ],
            'paint': {
                'circle-color': '#000',
                'circle-radius': 5
            }
        });

        this.map.addLayer({
            id: "locations_outer",
            source: "locations",
            type: "circle",
            filter: [
                "all"
            ],
            paint: {
                'circle-color': 'rgba(0,0,0,0)',
                'circle-stroke-color': '#000',
                'circle-stroke-width': 3,
                'circle-radius': 10
            }
        });

        this.map.addLayer({
            'id': 'locations_cluster_label',
            'type': 'symbol',
            'source': 'locations',
            'filter': [
                'all',
                ['==', ['get', 'cluster'], true]
            ],
            'layout': {
                'text-field': ['number-format', ['get', 'point_count'], {}],
                'text-font': ['Montserrat Bold', 'Arial Unicode MS Bold'],
                'text-size': 13
            },
            'paint': {
                'text-color': '#000'
            }
        });

        var bounds = new mapboxgl.LngLatBounds();
        if (locations && locations.features){
            locations.features.forEach(function(feature) {
                bounds.extend(feature.geometry.coordinates);
            });
            this.map.fitBounds(bounds, {padding: ZOOM_PADDING});
        }
    }

    render() {
        return (
            <div class="card shadow mb-4 vh-100">
                <div class="card-body vh-100">
                    <div id="exposure-map" class="mapbox-map" style={{
                        height: "100%"
                    }}/>
                </div>
            </div>
        )
    }
} 
export default ExposureMap;
