
import React, { Component } from "react"
import { ExposureContext } from "../context"

/**
 * ExposureItem Component, Show each row of the exposure table.
 */
class ActiveExposureItem extends Component {
  static contextType = ExposureContext;

  render() {
    return (
          <a class="dropdown-item" href="#" onClick={() => this.props.change(this.props.id)}>
            {this.props.id} | {this.props.name}
          </a>
    )
  }
}

export default ActiveExposureItem
