import React, { Component } from "react"
import ActiveExposureItem from "./ActiveExposureItem.js"
import { ExposureContext } from "../context"
import Moment from "moment"

/**
 * Component ModelTable show the information for the different models
 * in a table format. 
 *
 * @Childs: ModelItem
 *
 */
class ActiveModel extends Component {

  static contextType = ExposureContext;

  selectItem(model) {
    this.context.setState({active_model: model})
    this.context.reloadModel(model.id)
  }


  render() {
    var models = this.context.models
    models = Object.keys(models).map(
         function (key) { return models[key]; });

    let model_table;
    let geocoded = "N/A";
    // Condition is kind of redundant, used to prevent the case where
    // active_model object already exists but id has not been loaded yet.
    console.log(this.context.active_model)
    if (this.context.active_model) {
        let model = this.context.models[this.context.active_model];
        if (model.geocoded_match) {
            geocoded = model.geocoded_match + "%";
        }
        model_table = (
            <table class="table table-bordered mt-2" id="dataTable" width="100%" cellSpacing="0">
                <tbody>
                    <tr>
                        <td>{model.id}</td>
                        <td>{model.name}</td>
                        <td>{Moment(model.modified).format("DD/MM/YYYY")}</td>
                        <td>{geocoded}</td>
                    </tr>
                </tbody>
            </table>
        )
    }
    return (
        <div class="card shadow mb-4">
            <div class="card-body">
                <h3>Active Model Run</h3>
                <div class="dropdown mb-4">
                    <button class="btn btn-primary dropdown-toggle" type="button" id="dropdownModelButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        Select Model Run
                    </button>
                    <div class="dropdown-menu animated--fade-in" aria-labelledby="dropdownModelButton">
                        {models.map(exp => (
                            <ActiveExposureItem
                                id={exp.id}
                                name={exp.name}
                                change={this.context.setActiveModel}
                            />
                        ))}
                    </div>
                    {model_table}
                </div>
            </div>
        </div>
    )
  }
}

export default ActiveModel
