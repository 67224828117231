
import React, { Component } from "react"
import { ExposureContext } from "../context"

/**
 * ExposureItem Component, Show each row of the exposure table.
 */
class ExposureItem extends Component {
  static contextType = ExposureContext;
  render() {
    return (
      <tr>
          <td>{this.props.exposure.id}</td>
          <td>{this.props.exposure.name}</td>
          <td>{this.props.exposure.modified}</td>
          <td>{this.props.exposure.tiv}</td>
          <td>
              <div onClick={() => this.context.deleteExposure(this.props.id)} class="btn-sm btn-danger btn-circle">
                  <i class="fas fa-trash"></i>
              </div>
          </td>
      </tr>
    )
  }
}

export default ExposureItem
