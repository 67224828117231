import React, { Component } from "react"
import { ExposureContext } from "../context"

/**
 * Component ModelTable show the information for the different models
 * in a table format. 
 *
 * @Childs: ModelItem
 *
 */
class ActiveModel extends Component {
  static contextType = ExposureContext;

  render() {
  if (this.props.data === undefined){
    return ""
  }
  var datatable = [];
  var i;
  var analytical = "1";
  var sampled = "2";
  for (i =  0; i < this.props.data[analytical].return_period.length; i++) {
    datatable.push(
      <tr>
          <td>{this.props.data[analytical].return_period[i]}</td>
          <td>{this.props.data[analytical].loss[i]}</td>
          <td>{this.props.data[sampled].loss[i]}</td>
      </tr>
    )
  }
  if (this.props.aal !== undefined){
    datatable.push(
        <tr>
            <td>AAL</td>
            <td>{this.props.aal[analytical].mean[0]}</td>
            <td>{this.props.aal[sampled].mean[0]}</td>
        </tr>
    )
  }
    return (
        <div class="col-xl-5 col-lg-7">
            <div class="card-body">
                <div class="table-responsive">
                    <table class="table table-bordered" id="dataTable" width="100%" cellspacing="0">
                        <thead>
                            <tr>
                                <th>RP</th>
                                <th>Loss - Analytical</th>
                                <th>Loss - Sampled</th>
                            </tr>
                        </thead>
                        <tbody>
                          {datatable}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

    )
  }
}

export default ActiveModel
