import React, { Component } from "react"
import ExposureItem from "./ExposureItem"
import { ExposureContext } from "../context"

/**
 * Component ExposureTable show the information for the different exposures
 * in a table format. 
 *
 * @Childs: ExposureItem
 *
 */
class ExposureTable extends Component {
  static contextType = ExposureContext;
  render() {
    var exposures = this.context.exposures
    exposures = Object.keys(exposures).map(
         function (key) { return exposures[key]; });
    return (
        <div class="card shadow mb-4">
            <div class="card-body">
                <div class="table-responsive">
                    <table class="table table-bordered" id="dataTable" width="100%" cellspacing="0">
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Name</th>
                                <th>Uploaded</th>
                                <th>TIV</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                          {exposures.map(exp => (
                            <ExposureItem
                              id={exp.id} 
                              exposure={exp}
                            />
                          ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
  }
}

export default ExposureTable
