
import React, { Component } from "react"
import { ExposureContext } from "../context"

/**
 * Side bar with the menu to move between different components.
 */
class SideBar extends Component {
  static contextType = ExposureContext;

  loadOutputs() {
    this.context.setSelected("outputs")
    if (this.context.active_model) {
        this.context.modelLoadOutputs(this.context.active_model)              
    }
  }
  render() {
    return (
        <ul class="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion" id="accordionSidebar">

            <a class="sidebar-brand d-flex align-items-center justify-content-center" href="index.html">
                <div class="sidebar-brand-icon rotate-n-15">
                </div>
                <div class="sidebar-brand-text mx-3">Mitiga Volarisk <sup>Beta</sup></div>
            </a>

            <hr class="sidebar-divider my-0"/>

            <li 
              class={this.context.selected === "exposures" ?  "nav-item active": "nav-item"}
              onClick={() => this.context.setSelected("exposures")}
            >
                <div class="nav-link">
                    <i class="fas fa-fw fa-wrench"></i>
                    <span>Exposure</span>
                </div>
            </li>
            <li
              class={this.context.selected === "models" ?  "nav-item active": "nav-item"}
              onClick={() => this.context.setSelected("models")}
            >
                <div class="nav-link">
                    <i class="fas fa-fw fa-cog"></i>
                    <span>Model Run</span>
                </div>
            </li>
            <li 
              class={this.context.selected === "outputs" ?  "nav-item active": "nav-item"}
              onClick={() => this.loadOutputs()}
            >
                <div class="nav-link">
                    <i class="fas fa-fw fa-folder"></i>
                    <span>Outputs</span>
                </div>
            </li>
        </ul>
    )
  }
}

export default SideBar
