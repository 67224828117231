import React, { Component } from "react"
import TopBar from "./TopBar"
import ExposureTable from "./ExposureTable"
import CreateExposure from "./CreateExposure"
import ExposureMap from "./ExposureMap"
/**
 * View for exposures.
 *
 * Will show the information from already created exposures.
 * Will have the capability to create new exposures.
 *
 */
class ExposureOverview extends Component {
  render() {
    return (
        <div id="content-wrapper" class="d-flex flex-column">
          <div id="content">
            <TopBar />
            <div class="container-fluid">
              <div class="d-sm-flex align-items-center justify-content-between mb-4">
                <h1 class="h3 mb-0 text-gray-800">Exposure Overview</h1>
              </div>
              <ExposureTable />
              <CreateExposure />
                <ExposureMap />
            </div>
          </div>
        </div>
    )
  }
}

export default ExposureOverview 
