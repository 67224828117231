import React, { Component } from "react"

const ExposureContext = React.createContext()
const ExposureProvider = ExposureContext.Provider
// const ExposureConsumer = ExposureContext.Consumer

class MyContext extends Component {
  componentDidMount() {
    this.reloadExposure()
  }
  state = {
    "exposures": {},
    "models": {},
    "active_exposure": 0,
    "active_model": 0,
    "selected": "exposures",
    "model_outputs": {},
    "locations": {}
  }

/******************************************************************************
 *                              MENU METHODS
******************************************************************************/
  setSelected = (selected) => {
    this.setState({selected: selected});
  }
/******************************************************************************
 *                              EXPOSURE METHODS
******************************************************************************/
  /**
   * Reloads the exposure data (fetching oasis server)
   */
  reloadExposure = () => {
    fetch(window.location.origin+"/portfolios/")
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({exposures: result["response"]});
          console.log(this.state.exposures)
        },

        (error) => {
          this.setState({});
        }
      )
  }

  /**
   * Create a new exposure.
   *
   * We are create a form data to simulate html form submmit since this is
   * what is actually happen when you test with the server using swagger ui.
   * and seems a very nice way of doing so.
   *
   * @param name: Name of the new exposure.
   */
  createNewExposure = (name) => {
    var formData = new FormData();
    formData.append("username", "Groucho");
    formData.append(
        "location_file", document.getElementById("location").files[0]);
    var request = new XMLHttpRequest();
    request.open("POST", window.location.origin+"/portfolios/?name="+name);

    // We need this because inside the eventlistener the "this" change.
    let reloadExposureMethod = this.reloadExposure
    let setActiveExposureMethod = this.setActiveExposure
    // Wait request to finish and reload table
    request.addEventListener('readystatechange', function(e) {
      if( this.readyState === 4 ) {
        let response = JSON.parse(this.response)
        console.log(response)
        console.log(response["id"])
        setActiveExposureMethod(response["id"])
        reloadExposureMethod()
      }
    });
    request.send(formData);
  };

 /**
  * Delete exposure
  *
  * @param id: Exposure id
  */
  deleteExposure = (id) => {
    console.log("Deleting exposure", id)
    var request = new XMLHttpRequest();
    request.open("DELETE", window.location.origin+"/portfolios/"+id);

    // We need this because inside the eventlistener the "this" change.
    let reloadExposureMethod = this.reloadExposure

    // Wait request to finish and reload table
    request.addEventListener('readystatechange', function(e) {
      if( this.readyState === 4 ) {

        reloadExposureMethod()
      }
    });
    request.send();
  }


  /**
   * Load exposure location data (fetching oasis server)
   */
  loadLocations = (id) => {
    fetch(window.location.origin+"/portfolios/"+id+"/locations")
      .then(res => res.json())
      .then(
        (result) => {
          console.log(this.state.exposures)
          this.setState({"locations": result["response"]});
          console.log(this.state.locations)
        },
        (error) => {
          this.setState({"locations": {}});
        }
      )
  }

  /**
   * Load geocoded data (fetching oasis server)
   */
    loadGeocoded = () => {
    // Check if any model have the geocoded variable
	for (var key in this.state.models) {
        if (this.state.models[key]["geocoded_match"]) {
            fetch(window.location.origin+"/analyses/"+this.state.models[key]["id"]+"/locations")
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({"locations": result["result"]});
                },
                (error) => {
                    this.setState({"locations": {}});
                }
            )
	    return
        } else {
            this.setState({"locations": {}});
        }
    }
  }

 /**
  * Set active exposure
  *
  * @param id: Exposure id
  */
  setActiveExposure = (id) => {
    this.setState({active_exposure: id});
    //Reset active model to avoid errors when changing active exposure
    this.setState({active_model: 0});
    this.setState({model_outputs: {}});
    this.reloadModel(id);
  }

/******************************************************************************
 *                              MODEL RUN METHODS
******************************************************************************/

  /**
   * Reloads the model data (fetching oasis server)
   */
  reloadModel = (portfolio_id) => {
    var query = "";
    if (portfolio_id) {
      query += "?portfolio=" + portfolio_id;
    }
    fetch(window.location.origin+"/analyses/"+query)
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({models: result["response"]});
          console.log(this.state.models)
        },

        (error) => {
          this.setState({});
        }
      )
  }

  createNewModel = (name) => {
    var request = new XMLHttpRequest();
    request.open("POST", window.location.origin+
        "/analyses/?name="+name+"&exposure_id="+this.state.active_exposure);

    // We need this because inside the eventlistener the "this" change.
    let reloadModelMethod = this.reloadModel;
    let generateInputsMethod = this.generateInputs;
    let portfolio_id = this.state.active_exposure;
    // Wait request to finish and reload table
    request.addEventListener('readystatechange', function(e) {
      if( this.readyState === 4 ) {
          reloadModelMethod(portfolio_id);
          let model = JSON.parse(this.response);
	      generateInputsMethod(model["response"]["id"]);
      }
    });
    request.send();
  };

  generateInputs = (model_id) => {
      var request = new XMLHttpRequest();
      this.setState({active_model: model_id});
      request.open("POST", window.location.origin +
		   "/analyses/"+ model_id +"/generate_inputs");
      let reloadModelMethod = this.reloadModel
      let portfolio_id = this.state.active_exposure
      request.addEventListener('readystatechange', function(e) {
      if( this.readyState === 4 ) {
          reloadModelMethod(portfolio_id);
      }
    });
    request.send();
  };

  runModel = (model_id) => {
    console.log("Running model:", model_id)
    var request = new XMLHttpRequest();
    request.open("POST", window.location.origin +
		   "/analyses/"+ model_id +"/run");
    let reloadModelMethod = this.reloadModel
    let portfolio_id = this.state.active_exposure
    request.addEventListener('readystatechange', function(e) {
    if( this.readyState === 4 ) {
        reloadModelMethod(portfolio_id);
    }
    });
    request.send();
  };

 /**
  * Delete model
  *
  * @param id: Model id
  */
  deleteModel = (id) => {
    console.log("Deleting model run", id)
    var request = new XMLHttpRequest();
    request.open("DELETE", window.location.origin+"/analyses/"+id);
    var portfolio_id = this.state.active_exposure
    // We need this because inside the eventlistener the "this" change.
    let reloadModelMethod = this.reloadModel

    // Wait request to finish and reload table
    request.addEventListener('readystatechange', function(e) {
      if( this.readyState === 4 ) {
        reloadModelMethod(portfolio_id)
      }
    });
    request.send();
  }

  setActiveModel = (id) => {
    this.setState({active_model: id});
    this.modelLoadOutputs(id)
    console.log(this.state.model_outputs)
  }

  modelSetOutput = (data) => {
    this.setState({model_outputs:data})
  }

  modelLoadOutputs = (id) => {
    this.setState({model_outputs:{}});
    fetch(window.location.origin+"/analyses/"+id+"/outputs")
          .then(res => res.json())
          .then(
            (result) => {
                if (result["aep"] !== undefined){
                    this.setState({model_outputs:result});
                }
                console.log("got output OK: ", result)
                console.log("got STATUS OK: ", this.state.model_outputs)
            },
            (error) => {
                this.setState({model_outputs:{}});
                console.log("got output ERR: ", this.state.model_outputs)
            }
          )
  }

  /* *
   * Generate and download analisis report
   * */
  modelGenerateReport = (id) => {
    // Get report file from API
    fetch(window.location.origin+"/analyses/"+id+"/report", {
          method: 'GET',
        })
        // Process response by blocks
        .then(response => response.blob())
        .then(blob => {
            var url = window.URL.createObjectURL(blob);
            var a = document.createElement('a');
            a.href = url;
            a.download = "report_" + id + ".xlsx";
            document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
            a.click();
            a.remove();  //afterwards we remove the element again
        });
  };

  render() {
    return (
      <ExposureProvider
        value={{
          ...this.state,
          createNewExposure: this.createNewExposure,
          reloadExposure: this.reloadExposure,
          deleteExposure: this.deleteExposure,
          setActiveExposure: this.setActiveExposure,
          createNewModel: this.createNewModel,
          reloadModel: this.reloadModel,
          loadLocations: this.loadLocations,
          modelLoadOutputs: this.modelLoadOutputs,
          runModel: this.runModel,
          deleteModel: this.deleteModel,
          setSelected: this.setSelected,
          setActiveModel: this.setActiveModel,
          modelGenerateReport: this.modelGenerateReport,
          loadGeocoded: this.loadGeocoded
        }}
      >
        {this.props.children}
      </ExposureProvider>
    );
  }
}

export { ExposureContext, MyContext}
