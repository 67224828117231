
import React, { Component } from "react"
import { ExposureContext } from "../context"

/**
 * Component CreateExposure: This will handle the elements to create a new
 * exposure. Containing:
 *
 *  - The input field for the name.
 *  - File fields to upload: 
 *    - Location file.
 *    - Lccount file.
 *    - R1 info 
 *    - R1 scope
 *  - Create button to create the new exposure.
 *
 * @state:
 *   Contains the file name for the state
 *
 * @context:
 *   We will call the createNewExposure method with all the information
 *   need.
 *
 */
class CreateExposure extends Component {
  static contextType = ExposureContext;
  state = {
        "name": "" 
      }
  onChange = e => {
    this.setState({
        name:e.target.value
    });
  };
  render() {
    return (
      <div class="card shadow mb-4">
          <div class="card-body">
              <h5>Create New Exposure</h5>
              <p>Portfolio name:</p>
              <div class="row">
                  <div class="col-xl-6 col-md-6 mb-4">
                      <input
                        class="form-control form-control-user" 
                        value={this.state.name} 
                        name="edit"
                        onChange={this.onChange}
                      />
                      <p></p>
                  </div>
                  <div class="col-xl-3 col-md-6 mb-4">

                  </div>
              </div>
              <p>Upload files for {this.state.name}:</p>
              <div class="row">
                  <div class="col-xl-3 col-md-6 mb-4">
                      <div class="card border-left-success shadow h-100 py-2">
                          <div class="card-body">
                              <div class="row no-gutters align-items-center">
                                  <div class="col mr-2">
                                      <div class="text-xs font-weight-bold text-success text-uppercase mb-1">
                                          Location: 
                                      </div>
                                      <div class="font-weight-bold text-gray-800"><input type="file" id="location" name="location"/></div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div class="col-xl-3 col-md-6 mb-4">
                      <div class="card border-left-success shadow h-100 py-2">
                          <div class="card-body">
                              <div class="row no-gutters align-items-center">
                                  <div class="col mr-2">
                                      <div class="text-xs font-weight-bold text-success text-uppercase mb-1">
                                          Account:</div>
                                      <div class="font-weight-bold text-gray-800"><input type="file" id="account" name="account"/></div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div class="col-xl-3 col-md-6 mb-4">
                      <div class="card border-left-success shadow h-100 py-2">
                          <div class="card-body">
                              <div class="row no-gutters align-items-center">
                                  <div class="col mr-2">
                                      <div class="text-xs font-weight-bold text-success text-uppercase mb-1">
                                          R1 info</div>
                                      <div class="font-weight-bold text-gray-800"><input type="file" id="r1info" name="r1info"/></div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div class="col-xl-3 col-md-6 mb-4">
                      <div class="card border-left-success shadow h-100 py-2">
                          <div class="card-body">
                              <div class="row no-gutters align-items-center">
                                  <div class="col mr-2">
                                      <div class="text-xs font-weight-bold text-success text-uppercase mb-1">
                                          R1 Scope</div>
                                      <div class="font-weight-bold text-gray-800"><input type="file" id="r1scope" name="r1scope"/></div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div onClick={() => this.context.createNewExposure(this.state.name)} class="btn btn-success btn-icon-split">
                    <span class="icon text-white-50">
                      <i class="fas fa-check"></i>
                    </span>
                    <span class="text">Create</span>
                  </div>
                  <button onClick={() => this.context.loadLocations(this.context.active_exposure)}  disabled={!this.context.active_exposure} class="btn btn-success btn-icon-split">
                    <span class="icon text-white-50">
                      <i class="fas fa-check"></i>
                    </span>
                    <span class="text">Show locations</span>
                  </button>
              </div>
          </div>
        </div>
    )
  }
}

export default CreateExposure
