import React from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
import Wrapper from "./components/Wrapper"
import { MyContext } from "./context"

// Comment me if you want to see logs =) Have a nice day! 
console.log = function () {};

ReactDOM.render(
  <MyContext>
    <React.StrictMode>
      <Wrapper />
    </React.StrictMode>
  </MyContext>,
  document.getElementById('page-top')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
