import React, { Component } from "react"
import { ExposureContext } from "../context"
import GraphVisualization from "./GraphVisualization"
import TableVisualization from "./TableVisualization"

/**
 * Component ModelTable show the information for the different models
 * in a table format. 
 *
 * @Childs: ModelItem
 *
 */
class DataVisualization extends Component {
  static contextType = ExposureContext;

  render() {
    console.log("--> ", this.context.model_outputs.aep)
    console.log("--> type ", typeof this.context.model_outputs.aep)
    console.log("--> compare", this.context.model_outputs.aep === undefined)
    var data = this.context.model_outputs

    let aep = ""
    if (data.aep){
        aep = (
            <div class="row">
                <GraphVisualization 
                    data={data.aep}
                    name="aep"
                />
                <TableVisualization 
                    data={data.aep}
                    aal={data.aal}
                    name="AEP"
                />
            </div>
        )
    }
    let oep = ""
    if (data.oep) {
        oep = (
            <div class="row">
                <GraphVisualization 
                    data={data.oep}
                    name="oep"
                />
                <TableVisualization 
                    data={data.oep}
                    aal={data.aal}
                    name="OEP"
                />
            </div>
        )
    }
    return (
        <div class="card shadow mb-4">
            <div class="card-body" >
                <h3 style={{display: data.aep ? 'none' : 'block'}}>Ouputs</h3>
                <h5 style={{display: data.aep ? 'none' : 'block'}}>Please select a valid model run</h5>
                <h3 style={{display: data.aep ? 'block' : 'none'}}>OEP Ouputs</h3>
                {oep}
                <h3 style={{display: data.aep ? 'block' : 'none'}}>AEP Ouputs</h3>
                {aep}
            </div>
        </div>
    )
  }
}

export default DataVisualization
